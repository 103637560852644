import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocalForage } from "../hooks";

export type Collections = {
  cyberKing: number;
  genesisCyberGorilla: number;
  cyberGorilla: number;
  genesisBabyGorilla: number;
  babyCyberGorilla: number;
  banana: number;
  bananaBundle: number;
  bananaBox: number;
  bananaCrate: number;
};

const defaultValue: Collections = {
  cyberKing: 0,
  genesisCyberGorilla: 0,
  cyberGorilla: 0,
  genesisBabyGorilla: 0,
  babyCyberGorilla: 0,
  banana: 0,
  bananaBundle: 0,
  bananaBox: 0,
  bananaCrate: 0,
};

export const useStoreContext = () => {
  const ctx = useContext(StoreContext);

  if (!ctx) {
    throw new Error("useStoreContext was used outside of StoreProvider");
  }

  return ctx;
};

type ContextProps = {
  store: Collections;
  loading: boolean;
  setValue: (value: any) => void;
} | null;

const StoreContext = createContext<ContextProps>(null);

const StoreProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { localeStore, loading, setValue } = useLocalForage(
    "collections",
    defaultValue
  );

  const [store, setStore] = useState(localeStore);

  useEffect(() => {
    setStore(localeStore);
  }, [loading, localeStore]);

  const setValueCallback = useCallback(
    (value: any) => {
      setValue(value);
      const newStore = { ...store, ...value };
      setStore(newStore);
    },
    [setValue, store]
  );

  return (
    <StoreContext.Provider
      value={{ store, loading, setValue: setValueCallback }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export { StoreProvider };
