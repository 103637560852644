import { FC, ReactNode } from "react";

type Props = {
  title?: string;
  className?: string;
  children?: ReactNode;
};

const Box: FC<Props> = ({ title, children, className }) => {
  return (
    <div className={`Box ${className && className}`}>
      <div className="Box-header">
        <div className="Box-header-left"></div>
        <div className="Box-header-center">
          {title && (
            <div className="Box-header-label">
              <div className="Box-header-label-left"></div>
              <div className="Box-header-label-content">{title}</div>
              <div className="Box-header-label-right"></div>
            </div>
          )}
        </div>
        <div className="Box-header-right"></div>
      </div>
      <div className="Box-content">
        <div className="Box-content-left"></div>
        <div className="Box-content-center">{children}</div>
        <div className="Box-content-right"></div>
      </div>
      <div className="Box-bottom">
        <div className="Box-bottom-left"></div>
        <div className="Box-bottom-center"></div>
        <div className="Box-bottom-right"></div>
      </div>
    </div>
  );
};

export default Box;
