import { gql, useQuery } from "@apollo/client";
import Box from "../Box";
import Loading from "../Loading";

const QUERY_ETHEREUM_GRILLA = gql`
  query AppPrice_ethereumGrilla {
    ethereum(network: ethereum) {
      dexTrades(
        exchangeName: { is: "Uniswap" }
        baseCurrency: { is: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2" }
        quoteCurrency: { is: "0xdac17f958d2ee523a2206206994597c13d831ec7" }
        options: { desc: ["block.height", "transaction.index"], limit: 1 }
      ) {
        block {
          height
          timestamp {
            time(format: "%Y-%m-%d %H:%M:%S")
          }
        }
        transaction {
          index
        }
        baseCurrency {
          symbol
        }
        quoteCurrency {
          symbol
        }
        quotePrice
      }
    }
  }
`;

const QUERY_GRILLA_ETHEREUM = gql`
  query AppPrice_GrillaEthereum {
    ethereum(network: ethereum) {
      dexTrades(
        exchangeName: { is: "Uniswap" }
        baseCurrency: { is: "0xb104677a88eBF06c51BE5572A6C012Ff3F93DA57" }
        quoteCurrency: { is: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2" }
        options: { desc: ["block.height", "transaction.index"], limit: 1 }
      ) {
        block {
          height
          timestamp {
            time(format: "%Y-%m-%d %H:%M:%S")
          }
        }
        transaction {
          index
        }
        baseCurrency {
          symbol
        }
        quoteCurrency {
          symbol
        }
        quotePrice
      }
    }
  }
`;

const AppPrice = () => {
  const {
    loading: loadingEthereumGrilla,
    error: errorEthereumGrilla,
    data: ethereumGrilla,
  } = useQuery(QUERY_ETHEREUM_GRILLA);

  const {
    loading: loadingGrillaEthereum,
    error: errorGrillaEthereum,
    data: grillaEthereum,
  } = useQuery(QUERY_GRILLA_ETHEREUM);

  const getEthereumPrice = (key: string) => {
    if (loadingEthereumGrilla) {
      return <Loading />;
    }

    if (errorEthereumGrilla) {
      return "-";
    }

    const price = ethereumGrilla[key].dexTrades[0].quotePrice.toFixed(2);

    return price;
  };

  const getGrillaPrice = (key: string) => {
    if (loadingGrillaEthereum) {
      return <Loading />;
    }

    if (errorGrillaEthereum) {
      return "-";
    }

    return grillaEthereum[key].dexTrades[0].quotePrice.toFixed(8);
  };

  const renderData = () => {
    const ethereumPrice = getEthereumPrice("ethereum");
    const grillaEthereumPrice = getGrillaPrice("ethereum");
    const grillaPrice = ethereumPrice * grillaEthereumPrice;

    return (
      <table>
        <thead>
          <tr>
            <td>Currency</td>
            <td>Value</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ETH/USD:</td>
            <td>${ethereumPrice}</td>
          </tr>
          <tr>
            <td>CYBER/ETH</td>
            <td>Ξ{grillaEthereumPrice}</td>
          </tr>
          <tr>
            <td>CYBER/USD</td>
            <td>${grillaPrice.toFixed(8)}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <section className="App-price-info">
      <Box title="Price Info">{renderData()}</Box>
    </section>
  );
};

export default AppPrice;
