import Box from "../Box";
import logoImage from "../../assets/images/logo.png";
import { useStoreContext } from "../../context/StoreContext";
import { getCollectionsYield } from "./AppYields";

const Logo = () => {
  return <img src={logoImage} className="App-logo" alt="logo" />;
};

const AppTool = () => {
  const { store } = useStoreContext();

  if (!store) return null;

  const { totalYield } = getCollectionsYield(store);

  return (
    <section className="App-tool">
      <div className="App-logo-container">
        <Logo />
      </div>
      <div className="App-welcome">
        <Box>
          <div>Welcome to Cyber Gorilla Community Tool</div>
          <br />
          <div>Total collection value: ---</div>
          <div>
            Total daily $CYBER yield: <b>{totalYield} $CYBER</b>
          </div>
        </Box>
      </div>
    </section>
  );
};

export default AppTool;
