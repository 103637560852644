import Box from "../Box";
import { useStoreContext } from "../../context/StoreContext";

const AppCollection = () => {
  const { store, setValue } = useStoreContext();

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;

    setValue({ [name]: value });
  };

  return (
    <section className="App-tool">
      <Box title="My Collection">
        <div className="App-collection-field">
          <label>Cyber Kings</label>
          <input
            type="number"
            min="0"
            max="999"
            title="Cyber Kings"
            name="cyberKings"
            defaultValue={store?.cyberKing}
            onChange={onChangeInput}
          />
        </div>
        <div className="App-collection-field">
          <label>Genesis Cyber Gorilla</label>
          <input
            type="number"
            min="0"
            max="999"
            title="Genesis Cyber Gorilla"
            name="genesisCyberGorilla"
            defaultValue={store?.genesisCyberGorilla}
            onChange={onChangeInput}
          />
        </div>
        <div className="App-collection-field">
          <label>Cyber Gorilla</label>
          <input
            type="number"
            min="0"
            max="999"
            name="cyberGorilla"
            defaultValue={store?.cyberGorilla}
            onChange={onChangeInput}
          />
        </div>
        <div className="App-collection-field">
          <label>Genesis Baby Gorilla</label>
          <input
            type="number"
            min="0"
            max="999"
            name="genesisBabyGorilla"
            defaultValue={store?.genesisBabyGorilla}
            onChange={onChangeInput}
          />
        </div>
        <div className="App-collection-field">
          <label>Baby Cyber Gorilla</label>
          <input
            type="number"
            min="0"
            max="999"
            name="babyCyberGorilla"
            defaultValue={store?.babyCyberGorilla}
            onChange={onChangeInput}
          />
        </div>
        <div className="App-collection-field">
          <label>Banana</label>
          <input
            type="number"
            min="0"
            max="999"
            name="banana"
            defaultValue={store?.banana}
            onChange={onChangeInput}
          />
        </div>
        <div className="App-collection-field">
          <label>BananaBundle</label>
          <input
            type="number"
            min="0"
            max="999"
            name="bananaBundle"
            defaultValue={store?.bananaBundle}
            onChange={onChangeInput}
          />
        </div>
        <div className="App-collection-field">
          <label>BananaBox</label>
          <input
            type="number"
            min="0"
            max="999"
            name="bananaBox"
            defaultValue={store?.bananaBox}
            onChange={onChangeInput}
          />
        </div>
        <div className="App-collection-field">
          <label>BananaCrate</label>
          <input
            type="number"
            min="0"
            max="999"
            name="bananaCrate"
            defaultValue={store?.bananaCrate}
            onChange={onChangeInput}
          />
        </div>
      </Box>
    </section>
  );
};

export default AppCollection;
