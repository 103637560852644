import "./App.css";
//import useGoogleSheets from "use-google-sheets";
import AppPrice from "./components/sections/AppPrice";
import AppTool from "./components/sections/AppTool";
import AppYields from "./components/sections/AppYields";
import AppYieldTotal from "./components/sections/AppYieldTotal";
import AppFloorPrice from "./components/sections/AppFloorPrice";
import AppCollection from "./components/sections/AppCollection";

const App = () => {
  /*   const { data, loading, error } = useGoogleSheets({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
    sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID || "",
    sheetsNames: ["Collections"],
  }); */

  /*   if (loading) {
    return (
      <div className="App">
        <Logo />
      </div>
    );
  }

  if (error) {
    return <div>Error! Please refresh to try again or contact us!</div>;
  }
 */

  return (
    <div className="App">
      <div className="Background-4"></div>
      <div className="Background-2"></div>
      <div className="Background-1"></div>
      <div className="Background-3"></div>
      <header className="App-header">
        <AppTool />
        <AppCollection />
      </header>
      <main className="App-main">
        <AppPrice />
        <AppFloorPrice />
        <AppYields />
        <AppYieldTotal />
      </main>
    </div>
  );
};

export default App;
