import { gql, useQuery } from "@apollo/client";
//@ts-ignore
//import convert from "ethereum-unit-converter";
import { useGoogleSheetsContext } from "../../context/GoogleSheetsContext";
import Box from "../Box";
import Loading from "../Loading";

const query = gql`
  query AppFloorPrice {
    cybergorillas {
      collection {
        stats {
          floor_price
        }
      }
    }
    cyberbananas {
      collection {
        stats {
          floor_price
        }
      }
    }
    babycybergorillas {
      collection {
        stats {
          floor_price
        }
      }
    }
    cyberkings {
      collection {
        stats {
          floor_price
        }
      }
    }
  }
`;

const AppFloorPrice = () => {
  const { loading, error, data } = useQuery(query);
  const {
    loading: loadingGenesis,
    value: genesisValue,
    error: genesisError,
  } = useGoogleSheetsContext();

  const renderField = (key: string) => {
    if (loading) {
      return <Loading />;
    }

    if (error) {
      return "-";
    }

    return `Ξ${data[key].collection.stats.floor_price}`;
  };

  const renderGenesisField = (key: string) => {
    if (loadingGenesis) {
      return <Loading />;
    }

    if (genesisError) {
      return "-";
    }

    const data = genesisValue[0]?.data;
    const value = data?.find((item: any) => item.key === key)?.value;

    return `Ξ${value}`;
  };

  const renderBundleField = (key: string) => {
    if (loading) {
      return <Loading />;
    }

    if (error) {
      return "-";
    }

    const listings = data[key]?.listings;

    if (!listings && listings?.length < 1) {
      return "-";
    }

    //return `Ξ${convert(listings[0]?.base_price, "wei", "ether")}`;
    return "-";
  };

  const renderData = () => {
    const cyberkings = renderField("cyberkings");
    const cybergorillas = renderField("cybergorillas");
    const babycybergorillas = renderField("babycybergorillas");
    const cyberbananas = renderField("cyberbananas");
    const genesisGorilla = renderGenesisField("genesis-gorilla");
    const genesisBabyGorilla = renderGenesisField("genesis-baby-gorilla");
    const bananasBundle = renderBundleField("bananasbundle");
    const bananasBox = renderBundleField("bananasbox");
    const bananasCrate = renderBundleField("bananascrate");

    return (
      <table>
        <thead>
          <tr>
            <td>Name</td>
            <td>Price</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Cyber Kings:</td>
            <td>{cyberkings}</td>
          </tr>
          <tr>
            <td>Genesis Cyber Gorillas:</td>
            <td>{genesisGorilla}</td>
          </tr>
          <tr>
            <td>Cyber Gorillas:</td>
            <td>{cybergorillas}</td>
          </tr>
          <tr>
            <td>Genesis Baby Cyber Gorilla:</td>
            <td>{genesisBabyGorilla}</td>
          </tr>
          <tr>
            <td>Baby Cyber Gorilla:</td>
            <td>{babycybergorillas}</td>
          </tr>
          <tr>
            <td>Banana:</td>
            <td>{cyberbananas}</td>
          </tr>
          <tr>
            <td>BananaBundle:</td>
            <td>{bananasBundle}</td>
          </tr>
          <tr>
            <td>BananaBox:</td>
            <td>{bananasBox}</td>
          </tr>
          <tr>
            <td>BananaCrate:</td>
            <td>{bananasCrate}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <section className="App-price-info">
      <Box title="Floor Price Info">{renderData()}</Box>
    </section>
  );
};

export default AppFloorPrice;
