import { useStoreContext } from "../../context/StoreContext";
import Box from "../Box";
import { getCollectionsYield } from "./AppYields";

const AppYieldTotal = () => {
  const { store } = useStoreContext();

  if (!store) return null;

  const { totalYield } = getCollectionsYield(store);

  return (
    <section className="App-total-yield masonry-item">
      <Box title="Yield Other Time">
        <table>
          <thead>
            <tr>
              <td>Nr. days</td>
              <td>Total Value</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1 Day:</td>
              <td>{totalYield} $CYBER</td>
            </tr>
            <tr>
              <td>7 Days:</td>
              <td>{totalYield * 7} $CYBER</td>
            </tr>
            <tr>
              <td>30 Days:</td>
              <td>{totalYield * 30} $CYBER</td>
            </tr>
            <tr>
              <td>90 Days::</td>
              <td>{totalYield * 90} $CYBER</td>
            </tr>
            <tr>
              <td>180 Days:</td>
              <td>{totalYield * 180} $CYBER</td>
            </tr>
            <tr>
              <td>360 Days:</td>
              <td>{totalYield * 360} $CYBER</td>
            </tr>
          </tbody>
        </table>
      </Box>
    </section>
  );
};

export default AppYieldTotal;
