import ReactDOM from "react-dom/client";
import App from "./App";
//import reportWebVitals from "./reportWebVitals";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

import "./index.css";
import { StoreProvider } from "./context/StoreContext";
import { GoogleSheetsProvider } from "./context/GoogleSheetsContext";

const container = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(container);

const cache = new InMemoryCache();

const client = new ApolloClient({
  uri: "https://grilla-app-api.vercel.app/api",
  cache,
});

root.render(
  <ApolloProvider client={client}>
    <GoogleSheetsProvider>
      <StoreProvider>
        <App />
      </StoreProvider>
    </GoogleSheetsProvider>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
