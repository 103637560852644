import { Collections, useStoreContext } from "../../context/StoreContext";
import Box from "../Box";

export const getCollectionsYield = (store: Collections) => {
  const genesisCyberGorillas = store.genesisCyberGorilla * 250;
  const cyberGorillas = store.cyberGorilla * 100;
  const genesisBabyGorillas = store.genesisBabyGorilla * 75;
  const babyCyberGorillas = store.babyCyberGorilla * 50;
  const bananas = store.banana * 25;
  const bananaBundles =
    genesisCyberGorillas * 80 * 0.1 * store.bananaBundle +
    babyCyberGorillas * 50 * 0.1 * store.bananaBundle +
    store.bananaBundle * 400;
  const bananaBox =
    store.bananaBox * (cyberGorillas * 100 * 0.2) + store.bananaBox * 800;
  const bananaCrate =
    genesisCyberGorillas * 250 * store.bananaCrate +
    genesisBabyGorillas * 8 * store.bananaCrate +
    cyberGorillas * 20 * store.bananaCrate +
    babyCyberGorillas * 5 * store.bananaCrate +
    1250 * store.bananaCrate;

  const totalYield =
    genesisCyberGorillas +
    cyberGorillas +
    genesisBabyGorillas +
    babyCyberGorillas +
    bananas +
    bananaBundles +
    bananaBox +
    bananaCrate;

  return {
    genesisCyberGorillas,
    cyberGorillas,
    genesisBabyGorillas,
    babyCyberGorillas,
    bananas,
    bananaBundles,
    bananaBox,
    bananaCrate,
    totalYield,
  };
};

const AppYields = () => {
  const { store } = useStoreContext();

  if (!store) return null;

  const {
    genesisCyberGorillas,
    cyberGorillas,
    genesisBabyGorillas,
    babyCyberGorillas,
    bananas,
    bananaBundles,
    bananaBox,
    bananaCrate,
  } = getCollectionsYield(store);

  return (
    <section className="App-yields masonry-item">
      <Box title="Yields">
        <table>
          <thead>
            <tr>
              <td>Name</td>
              <td>Value</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Genesis Cyber Gorillas:</td>
              <td>{genesisCyberGorillas} $CYBER</td>
            </tr>
            <tr>
              <td>Cyber Gorillas:</td>
              <td>{cyberGorillas} $CYBER</td>
            </tr>
            <tr>
              <td>Genesis Baby Cyber Gorilla:</td>
              <td>{genesisBabyGorillas} $CYBER</td>
            </tr>
            <tr>
              <td>Baby Cyber Gorilla:</td>
              <td>{babyCyberGorillas} $CYBER</td>
            </tr>
            <tr>
              <td>Banana:</td>
              <td>{bananas} $CYBER</td>
            </tr>
            <tr>
              <td>BananaBundle:</td>
              <td>{bananaBundles} $CYBER</td>
            </tr>
            <tr>
              <td>BananaBox:</td>
              <td>{bananaBox} $CYBER</td>
            </tr>
            <tr>
              <td>BananaCrate:</td>
              <td>{bananaCrate} $CYBER</td>
            </tr>
          </tbody>
        </table>
      </Box>
    </section>
  );
};

export default AppYields;
