import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import useGoogleSheets from "use-google-sheets";

export type FlorPrices = {
  genesisCyberGorilla: string;
  genesisBabyGorilla: string;
};

export const useGoogleSheetsContext = () => {
  const ctx = useContext(GoogleSheetsContext);

  if (!ctx) {
    throw new Error(
      "useGoogleSheetsContext was used outside of GoogleSheetsProvider"
    );
  }

  return ctx;
};

type ContextProps = {
  value: any;
  loading: boolean;
  error: any;
} | null;

const GoogleSheetsContext = createContext<ContextProps>(null);

const GoogleSheetsProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { data, loading, error } = useGoogleSheets({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
    sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID || "",
    sheetsNames: ["Collections"],
  });

  const [value, setValue] = useState(data);

  useEffect(() => {
    setValue(data);
  }, [data]);

  return (
    <GoogleSheetsContext.Provider value={{ value, loading, error }}>
      {children}
    </GoogleSheetsContext.Provider>
  );
};

export { GoogleSheetsProvider };
