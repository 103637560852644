import { useState, useEffect, useCallback } from "react";
import localForage from "localforage";

export function useLocalForage<T>(key: string, initialValue?: T) {
  const [localeStore, setLocaleStore] = useState<any>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function get() {
      try {
        const value: T | null = await localForage.getItem(key);
        const newValue = { ...initialValue, ...value };
        localForage.setItem(key, newValue);
        setLocaleStore(newValue);
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    }

    get();
  }, [key, initialValue]);

  const setValue = useCallback(
    (value: any) => {
      const newValue = { ...localeStore, ...value };
      localForage.setItem(key, newValue);
      setLocaleStore(newValue);
    },
    [key, localeStore]
  );

  return { localeStore, setValue, loading };
}
